import React from "react"
import { css } from "@emotion/core"
import t from "theme"

const Carousel = ({ children }) => {
    return (
        <section
            css={css`
                display: flex;
                min-width: 100%;
                overflow-x: auto;
                ::-webkit-scrollbar {
                    background-color: transparent;
                }
                ::-webkit-scrollbar-track {
                    background-color: transparent;
                }
                ::-webkit-scrollbar-thumb {
                    background-color: transparent;
                }
            `}>
            {children.map((eachChild, index) => (
                <div
                    css={css`
                        min-width: 15rem;
                        height: 10rem;
                        background-color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: ${t.space[3]};
                        margin: ${t.space[3]} ${t.space[2]};
                        box-shadow: 0 0 0.45rem 0 ${t.color.border};
                        border-radius: 4px;
                    `}>
                    {eachChild}
                </div>
            ))}
        </section>
    )
}

export default Carousel

/*
<Nuka
slidesToShow={2}
cellSpacing={50}
slideWidth={1.3}
cellAlign="center"
withoutControls={true}
enableKeyboardControls={true}
wrapAround={true}
transitionMode="scroll3d"
opacityScale={0.6}>
{children.map((eachChild, index) => (
    <div
        css={css`
            width: 100%;
            padding: ${t.space[4]};
            height: 10rem;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: ${t.space[3]};
            box-shadow: 0 0 0.6rem 0 ${t.color.subtlegrey};
            border-radius: 4px;
        `}>
        <div css={css``}>{eachChild}</div>
    </div>
))}
</Nuka>
*/

/*

:hover {
                         transform: ${shadow ? "translateY(-3px) scale(1.01)" : "translateY(-3px) scale(1.01)"};
                         box-shadow: ${hovershadow
                             ? "0 0 0.3rem 0 " + t.color.subtlegrey
                             : shadow
                             ? "0 0 0.3rem 0 " + t.color.subtlegrey
                             : "none"};
                     }
                     :active {
                         transform: translateY(-1px) scale(1.01);
                     }

                     */
