import React from "react"
import { css } from "@emotion/core"
import t from "theme"

import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton } from "react-share"

const SocialShare = ({ linkUrl, ...props }) => {
    const pageUrl = "https://disruptionresearch.firebaseapp.com" + linkUrl

    return (
        <div {...props}>
            <p
                css={css`
                    margin-bottom: ${t.space[2]};
                `}>
                <b>Share this article </b>on your favorite social media platform!
            </p>

            <div
                css={css`
                    display: flex;
                    cursor: pointer;
                    font-size: ${t.font.size[10]};
                    & > *:not(:last-child) {
                        margin-right: ${t.space[4]};
                    }
                `}>
                <TwitterShareButton url={pageUrl}>
                    <i className="icon-twitter" />
                </TwitterShareButton>

                <FacebookShareButton url={pageUrl}>
                    <i className="icon-facebook2" />
                </FacebookShareButton>

                <LinkedinShareButton url={pageUrl}>
                    <i className="icon-linkedin" />
                </LinkedinShareButton>

                <EmailShareButton url={pageUrl}>
                    <i className="icon-mail" />
                </EmailShareButton>
            </div>
        </div>
    )
}

export default SocialShare
