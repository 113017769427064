import React, { useContext } from "react"
import { css } from "@emotion/core"
import MainLayout from "../layouts/Main"
import Header from "components/Common/Header"
import PageMetadata from "../components/Common/PageMetadata"
import SocialShare from "../components/UI/SocialShare"
import ArticleFull from "../components/ArticleFull"
import FeaturedArticlesSidebar from "../components/FeaturedArticlesSidebar"
import getFeaturedArticles from "../utils/getFeaturedArticles"
import { graphql } from "gatsby"
import linkResolver from "../utils/linkResolver"
import ColumnTitle from "components/UI/ColumnTitle"
import List from "components/UI/List"
import sort from "utils/sort"
import Landing from "components/Landing"
import SessionContext from "components/Common/SessionContext"
import CompanyWithIcon from "components/UI/CompanyWithIcon"
import Carousel from "components/UI/Carousel"
import t, { mixin } from "theme"

export const query = graphql`
    {
        articleArray: allContentfulArticle {
            edges {
                node {
                    ...ArticleContent
                }
            }
        }
    }
`

const Home = ({ pageContext: { commonData }, data: { articleArray }, location }) => {
    const featuredArticles = getFeaturedArticles(commonData)
    const articles = articleArray.edges.map(el => el.node)
    const session = useContext(SessionContext)
    const companies = commonData.liveCompanies

    const shouldLoad = session && session.user ? true : false

    const shouldShowLanding =
        shouldLoad &&
        session.user.uid === "anonymous" &&
        (!location.state || (location.state && !location.state.bypassed)) &&
        !session.bypassed

    const RealPage = () => (
        <MainLayout
            header={<Header commonData={commonData} pathname={location.pathname} />}
            sidebar={<FeaturedArticlesSidebar title="FEATURED ARTICLES" featureds={featuredArticles} homepage={true} />}
            uniqueComponent={
                <div
                    css={css`
                        margin-top: ${t.space[6]};
                    `}>
                    <div>
                        <ColumnTitle
                            css={css`
                                margin-top: 200px;
                                ${mixin.defaultEndsPadding}
                            `}>
                            BROWSE RESEARCH
                        </ColumnTitle>
                    </div>
                    <div
                        css={css`
                            ${mixin.fullwidth}
                            position: relative;
                        `}>
                        <Carousel>
                            {companies.map((company, index) => (
                                <CompanyWithIcon company={company} size={t.font.size[8]} />
                            ))}
                        </Carousel>
                    </div>
                </div>
            }
            isHome>
            <PageMetadata pagePathname={location.pathname} />

            <div>
                <ColumnTitle>LATEST ARTICLES</ColumnTitle>

                <List
                    items={articles.sort(sort("sortTimestamp", "desc")).map((article, index) => {
                        return (
                            <div>
                                <ArticleFull
                                    key={index}
                                    articleData={article}
                                    css={css`
                                        margin-bottom: ${t.space[4]};
                                    `}
                                />
                                <SocialShare
                                    linkUrl={linkResolver({
                                        slug: article.slug,
                                        type: "article",
                                    })}
                                />
                            </div>
                        )
                    })}
                    space={t.space[10]}
                />
            </div>
        </MainLayout>
    )

    return <RealPage /> /*shouldLoad ? shouldShowLanding ? <Landing /> : <RealPage /> : null*/
}

export default Home

/*

<>
                        {mainTransition.map(
                            ({ item, key, props }) =>
                                item && (
                                    <animated.div key={key} style={props}>
                                        <MainLayout
                                            header={<Header commonData={commonData} pathname={location.pathname} />}
                                            sidebar={
                                                <FeaturedArticlesSidebar
                                                    title="FEATURED ARTICLES"
                                                    featureds={featuredArticles}
                                                    homepage={true}
                                                />
                                            }
                                            
                                            isHome>
                                            <PageMetadata pagePathname={location.pathname} />

                                            <div>
                                                <ColumnTitle>LATEST ARTICLES</ColumnTitle>

                                                <List
                                                    items={articles
                                                        .sort(sort("sortTimestamp", "desc"))
                                                        .map((article, index) => {
                                                            return (
                                                                <div>
                                                                    <ArticleFull
                                                                        key={index}
                                                                        articleData={article}
                                                                        css={css`
                                                                            margin-bottom: ${t.space[4]};
                                                                        `}
                                                                    />
                                                                    <SocialShare
                                                                        linkUrl={linkResolver({
                                                                            slug: article.slug,
                                                                            type: "article",
                                                                        })}
                                                                    />
                                                                </div>
                                                            )
                                                        })}
                                                    space={t.space[10]}
                                                />
                                            </div>
                                        </MainLayout>
                                    </animated.div>
                                )
                        )}
                    </>
                    <>
                        {landingTransition.map(
                            ({ item, key, props }) =>
                                item && (
                                    <animated.div key={key} style={props}>
                                        <Landing bypassed={bypassed} setBypassed={setBypassed} />
                                    </animated.div>
                                )
                        )}
                    </>
                </>

                */
