import React from "react"
import { css } from "@emotion/core"
import linkResolver from "../utils/linkResolver"
import ContentDecoration from "./UI/ContentDecoration"
import RichText from "./UI/RichText"
import { Link } from "gatsby"
import DateLabel from "components/UI/DateLabel"
import t from "theme"

const ArticleFull = ({ articleData, ...props }) => {
    const company = articleData.companies ? articleData.companies[0] : null

    const articleType = articleData.articleType
    const linkUrl = linkResolver({ slug: articleData.slug, type: "article" })
    const body = articleData.text.json
    const headline = articleData.headline
    const sortTimestamp = articleData.sortTimestamp
    return (
        <div {...props}>
            <div
                css={css`
                    margin-bottom: ${t.space[6]};
                `}>
                <ContentDecoration type={articleType} company={company} />

                <h1
                    css={css`
                        padding-top: ${t.space[2]};
                        padding-bottom: ${t.space[2]};
                    `}>
                    <Link to={linkUrl}>{headline}</Link>
                </h1>

                <DateLabel date={sortTimestamp} />
            </div>

            <RichText body={body} linkUrl={linkUrl} />
        </div>
    )
}

export default ArticleFull
